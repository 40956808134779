.question-block {
  width: 48px;
  height: 48px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.question-block-text {
  font-weight: bold;
  color: #000;
}

.question-block-green {
  font-weight: bold;
  background-color: #ace296;
}

.question-block-red {
  font-weight: bold;
  background-color: #f38484;
}

.question-block-yellow {
  font-weight: bold;
  background-color: #e1e6a8;
}

.question-block-grey {
  font-weight: bold;
  background-color: rgb(228, 228, 228);
}

.question-block-border-blue {
  border: 5px solid #4d8cf0;
}

.labels-container {
  margin-top: 24px;
}

.label {
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-top: 12px;
}

.label-color {
  padding: 4px 10px;
  border: 1px solid #888;
}

.label-color.question-block-border-blue {
  border: 1px solid #4d8cf0;
  background-color: #4d8cf0;
}

.label-text {
  margin-left: 10px;
}