.test-footer {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ccc;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.04)
}

.test-footer-left {
  margin-right: auto;
}

.test-footer-right {
  margin-left: auto;
}