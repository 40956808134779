.test-outer-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
}

.test-left-container {
  width: auto;
  height: auto;
  display: flex;
  flex: 3;
  flex-direction: column;
  border-right: 1px solid #ccc;
}

.test-left-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.test-right-container {
  width: auto;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
}